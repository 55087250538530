import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { Heading } from '@/components/Heading'
import { BodyText } from '@/components/BodyText'
import { SectionRouter } from '@/components/SectionRouter'
import { Section } from '@/components/Section'

import { Cta } from '@/sections/Cta'
import { Text } from '@/sections/Text'
import { Image } from '@/sections/Image'
import { Video } from '@/sections/Video'

import * as styles from './BlogPost.styles.scss'

const BlogPost = ({ title, date, intro, sections }) => {
  const hasLongTitle = title.length > 40

  return (
    <div className={styles.BlogPost}>
      <div>
        <Section hasPaddingTop={false} bgColor="black">
          <Container className={styles.head}>
            <div className={styles.head__inner}>
              <div className={styles.date}>{date}</div>
              <Heading
                type={hasLongTitle ? 'h2' : 'h1'}
                className={styles.heading}
              >
                <div
                  className={
                    hasLongTitle
                      ? styles.heading__textLong
                      : styles.heading__textShort
                  }
                >
                  {title}
                </div>
              </Heading>
              {intro && (
                <BodyText type="p2" className={styles.intro}>
                  {intro}
                </BodyText>
              )}
            </div>
          </Container>
        </Section>
        <SectionRouter
          types={{
            text: Text,
            image: Image,
            cta: Cta,
            video: Video,
          }}
          sections={sections}
        />
      </div>
    </div>
  )
}

BlogPost.defaultProps = {
  sections: [],
}

BlogPost.propTypes = {
  title: PropTypes.string,
  date: PropTypes.node,
  intro: PropTypes.node,
  sections: PropTypes.array,
}

export default BlogPost
