import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { get } from '@/utils/get'
import { sectionSerializer } from '@/prismic/utils/sectionSerializer'
import { getRichText } from '@/prismic/utils/getRichText'
import { linkResolver } from '@/prismic/utils/linkResolver'
import BlogPost from '@/templates/BlogPost'

const BlogPostPrismic = ({ data }) => {
  const { prismicBlogPost } = data
  const slices = get(prismicBlogPost, 'data.body') || []
  const sections = sectionSerializer({ slices })

  return (
    <BlogPost
      title={prismicBlogPost.data.title}
      date={prismicBlogPost.data.date}
      intro={getRichText(prismicBlogPost, 'data.intro.raw')}
      sections={sections}
    />
  )
}

BlogPostPrismic.propTypes = {
  data: PropTypes.object.isRequired,
}

export default withPrismicPreview(BlogPostPrismic, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver,
  },
])

export const query = graphql`
  query BlogPostByID($id: String!) {
    prismicBlogPost(id: { eq: $id }) {
      _previewable
      uid
      id
      type
      data {
        title
        date(formatString: "Do MMMM YYYY")
        intro {
          raw
        }
        body {
          ... on PrismicBlogPostDataBodyText {
            id
            slice_type
            primary {
              background_color
              body_text {
                raw
              }
            }
          }
          ... on PrismicBlogPostDataBodyImage {
            id
            slice_type
            primary {
              background_color
              image {
                fluid(maxWidth: 1600) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicBlogPostDataBodyCta {
            id
            slice_type
            primary {
              background_color
              link_label
              link {
                document {
                  ... on PrismicPage {
                    uid
                    type
                  }
                }
                link_type
                target
                url
              }
            }
          }
          ... on PrismicBlogPostDataBodyVideo {
            id
            slice_type
            primary {
              background_color
              video_url
              aspect_ratio
              poster {
                fluid(maxWidth: 1600) {
                  ...GatsbyImgixFluid_noBase64
                }
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`
